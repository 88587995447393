import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'gatsby';
import Helmet from 'react-helmet';
import logoWhite2 from '../images/logo-white2.png'
import pdf from './Marc-Rummel-FSG-with-South-Yarra-Details-September-2019.pdf' 
import ff from './Solutions-Privacy-policy-September-2019.pdf' 
export type FooterProps = {
    type?: "HOMEPAGE" | "SIMPLE"
}
export function Footer(props: FooterProps) {
    let d = new Date();
    let year = d.getFullYear();
    return (
        <footer className="footer footer-big" style={{ padding: 0, backgroundColor: 'rgb(1, 107, 146)', color: '#fff' }}>
            <Helmet>
                <style>
                    {
                        `
                            .info .icon i {
                                background: #ffd400;
                                padding: 15px;
                                border-radius: 50%;
                                color: #15abe4;
                                font-size: 30px;
                            }
                        `
                    }
                </style>
            </Helmet>
            <section style={{ padding: "30px 0", borderBottom: '1px solid #fff', display: props.type === "HOMEPAGE" ? "" : 'none' }} >
                <Container>
                    <Row>
                        <Col md="4" style={{ textAlign: 'center' }}>
                            <div className="info info-hover" style={{ padding: '30px 0' }}>
                                <div className="icon">  <i style={{color: 'rgb(1, 107, 146)'}} className="now-ui-icons tech_headphones"></i></div>
                                <h4 className="info-title sub-header" ><a style={{ color: '#fff', fontSize: 15 }} href="tel:+61398266655">+61 3 9826 6655</a></h4>
                            </div>
                        </Col>
                         
                        <Col md="4" style={{ textAlign: 'center' }}>
                            <div className="info info-hover" style={{ padding: '30px 0' }}>
                                <div className="icon"> <i style={{color: 'rgb(1, 107, 146)'}} className="now-ui-icons business_bank"></i> </div>
                                <h4 style={{ color: '#fff   ', fontSize: 15 }} className="info-title  sub-header" >Suite 209 9-11 Claremont Street  <br /> South Yarra 3141</h4>
                            </div>
                        </Col>
                        

                        <Col md="4" style={{ textAlign: 'center' }} >
                            <div className="info info-hover" style={{ padding: '30px 0' }}>
                                <div className="icon"><i style={{color: 'rgb(1, 107, 146)'}} className="now-ui-icons ui-1_email-85"></i></div>
                                <h4 className="info-title  sub-header" ><a style={{ color: '#fff   ', fontSize: 15 }} href="mailto:info@solutionsconsultants.com.au" >info@solutionsconsultants.com.au</a></h4>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section style={{ padding: '50px 0', textAlign: "left", display: props.type === "SIMPLE" ? "none" : "", color: '#fff' }}>
                <Container>
                    <Row>
                        <Col md={3}>
                        <img src={logoWhite2} alt="" width="80%" />
                      <h3 style={{ fontSize: '1.35em',  color: '#fff', lineHeight: 1, marginTop: 0 }}>Financial Consultants</h3>
                             
                        </Col>

                        <Col md={3}>
                            <h5>HELP</h5>
                            <ul className="links-vertical">
                                <li><Link to="/personal-solutions">PERSONAL SOLUTIONS</Link></li>
                                <li><Link to="/retirement-solution">RETIREMENT SOLUTIONS</Link></li> 
                                <li><a href={ff} target="_blank">SOLUTIONS Privacy Policy</a></li>
                                <li><Link to="/contact">Contact Us</Link></li> 
                            </ul>
                        </Col>
                        <Col md={3}>
                            <h5>LINKS</h5>
                            <ul className="links-vertical">
                                
                                <li><a href={pdf} target="_blank">SOLUTIONS FSG</a></li>
                                <li><a href="http://www.ato.gov.au/" target="_blank">ATO</a></li>
                                <li><a href="https://www.moneysmart.gov.au/?referrer=fido.gov.au" target="_blank">Money Smart</a></li>
                                <li><a href="https://www.humanservices.gov.au/?utm_id=7" target="_blank">Centrelink</a></li>
                            </ul>
                        </Col>
                        <Col md={3}>
                        <iframe 
                            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsolutionsfinancialconsultants%2F&tabs=timeline&width=340&height=190&small_header=true&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=2507414546166486" 
                            width="340"
                            height="190" 
                            style={{border: 'none', overflow: 'hidden'}}
                            scrolling="no"   
                            allow="encrypted-media"></iframe>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section style={{ background: '#ffd400', padding: "15px", color: '#000' }}>
                <Container>
                    <Row>
                        <Col md={6} style={{ textAlign: "left" }}>
                            &copy;{year} All Rights Reserved Solutions Consultants
                    </Col>
                        <Col md={6}>
                            <p style={{ display: props.type === "SIMPLE" ? "" : 'none', margin: 0 }} >
                                <Link to="/faqs">FAQ’s</Link>   |  <Link to="/privacy-policy">Privacy Policy</Link> | <Link to="/contact-us">Contact Us</Link>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </footer>
    )
}