import React, { ReactNode } from "react"
import "../themes/css/bootstrap.min.css"
import "../themes/css/now-ui-kit.min.css"
import "../themes/demo/demo.css"
import "./layout.css"

import { NavBarIndex } from "./navbar"
import { Footer } from "./footer"
import Helmet from "react-helmet"
import { useFirebase } from "gatsby-plugin-firebase"
import mainbg from '../images/main-bg.jpg'

type SeoContent = {
  title: string,
  description: string,
  keyword: string
}

export type LayoutProps = {
  children: ReactNode
  defaultClass?: string
  banner: JSX.Element
  lang?: string
  seo: SeoContent
  footerType?: "HOMEPAGE" | "SIMPLE"
  navStayle?: "TRANSPARANT" | "SIMPLE"
  setUid: (uid: string) => any
}

const Layout = (props: LayoutProps) => {

  useFirebase((fb: any) => {
    // const firebaseApp = fb.initializeApp(firebaseConfig);
    fb.auth().signInAnonymously()
      .then((d: any) => {
        console.log('User data: ', d.user);
        props.setUid(d.user.uid)
      }).catch((exc: any) => {
        console.error('Gagal login, error: ', exc);
      });
    console.log("Masuk FB")
  }, [])

  return (
    <>
      <NavBarIndex defaultClass={props.navStayle ? props.navStayle === "TRANSPARANT" ? 'navbar-transparent' : 'bg-white' : 'navbar-transparent'} />
      <Helmet
        htmlAttributes={{
          lang: props.lang ? props.lang : '',
        }}
        bodyAttributes={{
          class: 'index-page sidebar-collapse'
        }}
        title={props.seo.title}

      >
        <meta name="description" content={props.seo.description} />
        <meta name="og:title" property="og:title" content={props.seo.title} />
        <meta name="og:description" property="og:description" content={props.seo.description} />
        <meta name="keywords" content={props.seo.keyword} />
        <meta name="author" content="I Putu Arka Suryawan " />
        <meta name="robots" content="index, follow"></meta>
        <link href="https://fonts.googleapis.com/css?family=Montserrat:400,700,200" rel="stylesheet" />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.7.1/css/all.css" />
        <link href="https://fonts.googleapis.com/css2?family=Sedgwick+Ave&display=swap" rel="stylesheet" />
        <style>
          {
            `
            body {
              background-image: url(${mainbg})
            }
            `
          }
        </style>
        <script type="text/javascript" src="//script.crazyegg.com/pages/scripts/0056/1606.js"  ></script>
        <script async defer src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDKI7MIqj064dRWv0ynvMujy078D5yehzU&callback=initMap">
        </script>

      </Helmet>
      <div className="wrapper">
        {props.banner}
        <div className="main">
          {props.children}
          <Footer type={props.footerType ? props.footerType : undefined} />
        </div>
      </div>

    </>
  )
}


export default Layout
