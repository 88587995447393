import React from 'react'
import { Navbar, Container, Collapse, Nav, NavItem, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, } from 'reactstrap';
import { Link } from 'gatsby';
import 'firebase' 
import logoBlack from '../images/logo-black.png'
export type NavBarProps = {
    defaultClass: string;
    firebaseApp?: firebase.app.App
}
export function NavBarIndex(props: NavBarProps) {

    let defNavClass = props.defaultClass
    // let history = useHistory()
    const [navbarColor, setNavbarColor] = React.useState(defNavClass);
    const [collapseOpen, setCollapseOpen] = React.useState(false);
    let devLogo = <img src={logoBlack} alt="" height={40} style={{ marginBottom: 0, marginTop: '-15px' }} />

    let href = '/'
    if (typeof window !== `undefined`) {
        href = window.location.pathname
    }

    React.useEffect(() => {

        const updateNavbarColor = () => {

            if (
                document.documentElement.scrollTop > 34 ||
                document.body.scrollTop > 34
            ) {
                setNavbarColor("");

            } else if (
                document.documentElement.scrollTop < 35 ||
                document.body.scrollTop < 35
            ) {
                setNavbarColor(defNavClass);

            }
        };

        window.addEventListener("scroll", updateNavbarColor);

        return function cleanup() {
            window.removeEventListener("scroll", updateNavbarColor);
        };
    }, [defNavClass]);
    return (
        <>
            {collapseOpen ? (
                <div
                    id="bodyClick"
                    onClick={() => {
                        document.documentElement.classList.toggle("nav-open");
                        setCollapseOpen(false);
                    }}
                />
            ) : null}

            <Navbar className={"fixed-top bg-white " + navbarColor} expand="lg" color="info" >
                <Container>
                    <div className="navbar-translate">
                        <Link to="/" id="navbar-brand" className="navbar-brand  ">
                            {devLogo}
                        </Link>
                        <button
                            className="navbar-toggler navbar-toggler"
                            onClick={() => {
                                document.documentElement.classList.toggle("nav-open");
                                setCollapseOpen(!collapseOpen);
                            }}
                            aria-expanded={collapseOpen}
                            type="button"
                        >
                            <span style={{ background: '#000' }} className="navbar-toggler-bar top-bar"></span>
                            <span style={{ background: '#000' }} className="navbar-toggler-bar middle-bar"></span>
                            <span style={{ background: '#000' }} className="navbar-toggler-bar bottom-bar"></span>
                        </button>
                    </div>
                    <Collapse
                        className="justify-content-end"
                        isOpen={collapseOpen}
                        navbar
                    >
                        <Nav className="ml-auto"  navbar>
                            <NavItem>
                                <Link className="nav-link " to="/" onClick={() => {
                                    document.documentElement.classList.toggle("nav-open");
                                    if (href !== '/') {
                                        setCollapseOpen(!collapseOpen);
                                    }
                                }}>
                                    <p>Home</p>
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link className="nav-link " to="/who-we-are" onClick={() => {
                                    document.documentElement.classList.toggle("nav-open");
                                    if (href.indexOf('/who-we-are') < 0) {
                                        setCollapseOpen(!collapseOpen);
                                    }
                                }}>
                                    WHO WE ARE
                                </Link>
                            </NavItem>
                            <UncontrolledDropdown nav>
                                <DropdownToggle
                                    aria-haspopup={true}
                                    caret
                                    color="default"
                                    data-toggle="dropdown"
                                    id="navbarDropdownMenuLink"
                                    nav
                                >
                                    <p>Personal Solutions</p>
                                </DropdownToggle>
                                <DropdownMenu aria-labelledby="navbarDropdownMenuLink">
                                    <DropdownItem
                                        href="/financial-planning"
                                    >  Financial Planning  </DropdownItem>
                                    <DropdownItem
                                        href="/insurance"
                                    > Insurance </DropdownItem>
                                    <DropdownItem
                                        href="/smsf"
                                    > Self-Managed Super Fund </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <NavItem>
                                <Link className="nav-link " to="/retirement-solution" onClick={() => {
                                    document.documentElement.classList.toggle("nav-open");
                                    if (href.indexOf('/retirement-solution') < 0) {
                                        setCollapseOpen(!collapseOpen);
                                    }
                                }}>
                                    RETIREMENT SOLUTIONS
                                </Link>
                            </NavItem>
                            <NavItem>
                                <Link className="nav-link " to="/meet-the-team" onClick={() => {
                                    document.documentElement.classList.toggle("nav-open");
                                    if (href.indexOf('/meet-the-team') < 0) {
                                        setCollapseOpen(!collapseOpen);
                                    }
                                }}>
                                    MEET THE TEAM
                                </Link>
                            </NavItem>
                            <UncontrolledDropdown nav>
                                <DropdownToggle
                                    aria-haspopup={true}
                                    caret
                                    color="default"
                                    data-toggle="dropdown"
                                    id="navbarDropdownMenuLink"
                                    nav
                                >
                                    <p>More</p>
                                </DropdownToggle>
                                <DropdownMenu aria-labelledby="navbarDropdownMenuLink">
                                    <DropdownItem
                                        href="/blog"
                                    >  Blog  </DropdownItem>
                                    <DropdownItem
                                        href="/contact"
                                    > Contact Us </DropdownItem> 
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    )
}

/*


*/